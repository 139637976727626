$system-grey: #242424;

* {
  box-sizing:border-box;
}

body {
  margin: 0px;
}

.app {
  
  position:relative;
  width:100%;
  height: 100%;
}
::-webkit-scrollbar {
  width: 0;
  scrollbar-color: green;
} 


.container {
  height:100%
}

@media only screen and (max-width: 400px){
  .nav {
    position: sticky;
    top: 0;
    left: 0;
    background-color: $system-grey;
    color: white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 1rem;
    padding: 2rem;
    z-index: 100;
    width: 100%;
    flex-direction: row;
    
  }
  .nav.hidden {
    position: sticky;
    top: 0;
    left: 0;
    background-color: $system-grey;
    color: white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 1rem;
    padding: 2rem;
    z-index: 100;
    flex-direction: column;
    
  }
  
  .nav ul {
    display:none;
  }
  
  nav.active {
    display:none
  }

  .site-title {
    font-size: 1.8rem;
  }

  .menu-button {
    display: block;
    margin: 8px;
  }
  .external-links {
  }

  .external-links.hidden  {
    display: none;
    
  }
  .custom-links {
 
    display: none;

    &.hidden {
      display:none;
    }
  }
  .about-me {
    width: 100%;
    display: inline;
    background-color: #333;
    h1 {
      font-size: 6vh;
    }
  }
  
  .particles {
    justify-content: center;
    position:relative;
    width: 100%;
    min-height: 700px;
    height:700px;
    display:flex;
    align-items: center;
    
   
  }
  .hello {
    position: relative;
    color: whitesmoke;
    text-align: center;
    vertical-align: center;
    margin: 0 auto;
    font-size: 40px;
    // margin-top: 250px;
  }
  
  .intro-text {
  
    /* position:relative; */
    width: 100%;
    background-color: #333;
    p {
      font-size: large;
      margin-left: 20%;
      margin-right: 20%;
    
    }
    
    a {
      color: inherit;
      text-decoration: none;
     
    }
  }
  
  

  
}


@media only screen and (min-width: 401px) {
  
  .menu-button {
    display:none;
  }
  .site-title {
    margin-bottom: 0px;
    font-size: 2rem;
  }
  .nav {
    position:sticky;
    top: 0;
    left: 0;
    width: 100%;
    background-color: $system-grey;
    color: white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 2rem;
    /* padding: 0 1rem; */
    z-index: 100;
    
  }
  .external-links {
    size: 10px;
  }
  .custom-links {
    display:none;
  }
  .particles {
    position:relative;
    width: 100%;
    min-height: 700px;
    height:700px;
    display:flex;
    align-items: center;
    vertical-align: center;
    
  }
  .hello {
    position: relative;
    color: whitesmoke;
    font-size: 50px;
    display:inline-block;
    vertical-align: center;
    align-items: center;
    margin: 0 auto;
   
  }
  
  
  .about-me {
    width: 100%;
    display: flex;
    background-color: #333;
    h1 {
      font-size: 6vh;
    }
  }
  
  .intro-text {
    text-align:left;
    align-content: center;
     p {
      font-size: large;
      margin-left: 0%;
      margin-right: 20%;
      
    
    }
    
     a {
      color: inherit;
      text-decoration: none;
      
    }

  
  }
  
  
  
}

.image {
  display: inline-block;
  position: absolute;
  max-width:100%;
  max-height:100%;
 
  text-align: right;
  padding-top: 5%;
  padding-left: 5%;
  
  padding-right: 5%;
  
}

.projects {
  position: relative;
  height: 100%;
  width: 100%; 
  color: white;
  background-color:#333;
  display:flex;
  padding-bottom: 20%;
  &::-webkit-scrollbar {
    width: 0px;
    scrollbar-color: green;
  } 
}



.projects ul {
  
  max-width: 80%;
  list-style: none;
  // justify-content: center;
}
.project-card {
  // border: 1px solid #ccc;
  border-radius: 15px;
  background-color:#4a4848;
  padding-right: 2%;
  padding-left: 2%;
  padding-bottom:2%;
  width: 100%;
  margin-left: 10%;
  margin-right: 10%;
  margin-top: 5%;
  height: fit-content;
  text-align: center;
  

  &:hover {

    transform: translateY(-1.5%);
    box-shadow: 8px 8px rgb(46, 46, 46);

  }
  transition: all linear 0.2s;

  

}


.project-description {
  justify-content: left;
  text-align: left;
}

.project-description h1 {
  font-size: 18px;
  margin-bottom: 10px;
  justify-content: left;
  line-height: 1.6 * 18px;
  color:#ccc
  
}

.project-description h2 {
  letter-spacing: 1.4px;
  
  
}

.project-buttons {
  margin-top: 20px;
}

.info {
  
  font-size:100%;
  padding-top: 0%;
  display: flex;
  padding-left: 10%;
  padding-right: 20%;
  position:absolute;
  text-align: left;
  
  
}



#tsparticles {
  position: absolute;
  min-height: 700px;
  width: 100%;
  height:100%;
}
.home {
  height: 100%;
  position:relative;
  width: 100%; 
  text-align:center;
  color: white;
  background-color: #2a2828;
  display:flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  

}

.btn-floating {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.2);
  border: 1px solid rgba(245, 245, 245, 0.075);
  text-align: center;
  padding: 0px;
  font-size: 24px;

  transition: padding-bottom .1s linear;

}
.btn-floating:hover {
  padding-bottom: 1%;
}
.intro {
  display:flex;
  /* flex-direction: column; */
}





.contact-title {
  color: white;
  align-content: center;

}
.contact {
  
  text-align: center;
  height: 100%;
  position: absolute;
  width: 100%; 
  
  background-color: #4c768d;
  .contact li {
  
    padding-top: 5%;
    padding-bottom: 0%;
    display: block;
  
  }
  
}




.uiuc {
  color: #dc6f30
}

.nav {
  ul {
    height:100%;
    padding: 0;
    margin: 0;
    list-style: none;
    display:flex;
    gap: 0.7rem;
    
  }
  a {
    color: inherit;
    text-decoration: none;
    height: 100%;
    display: flex;
    align-items: stretch;
    padding: .25rem;
    
  }
  li {
    padding-top: 7px;
    padding-bottom: 7px;
    height:100%;
    &.active {
      background-color: #555;
      margin: 0;
    }
    &:hover {
      background-color: #777;  
    }
  }
  
}



    
    

  








.sign-in {
  display: flex; 
  justify-content: center; 
  align-items: center; 
  height: 50vh; 
  margin: 0; 
}

.projects-text {
  color:#1DB954;
}

.project-card button {
  
  background-color: #1DB954;
  color: white; 
  border: none; 
  border-radius: 50px; 
  padding: 15px 32px; 
  text-align: center; 
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 20px auto; 
  display: block; 
  width: 200px; 
  
}

.project-card button:hover {
  background-color: #1fc459;
  cursor: pointer;
  
}



.icons {
  filter: invert(100%)
}



.diego-image {
  
  max-width: 200px;
  max-height: 300px;
  aspect-ratio: auto;
  border-radius: 3%;
  margin: 3%;
  
}

.intro {
  padding-top: 5%;
  /* display: inline-block; */
}

.tech {  
  position: relative;
  
  height: 100%;
  width: 100%;
  background-color: #444;
  color: white;
  display: inline-block;
  /* flex-direction: column; */
  justify-content: space-between;
  text-align: center;
  align-items: center;
  gap: 2rem;
  padding: 0 1rem;
}
.tech h1 {
  font-size:7vh;
  
}

.diego {
  color: rgb(61, 61, 223);
  font-weight:1000;
}

.arrow {
  filter: invert(100%);
  padding-top: 3%;
}


.explore {
  position: relative;
  
  padding:0.5%;
  background-color:#555;
  color:whitesmoke;
  font-weight: 600;
  border-radius: 8%;
  border-color: rgb(61, 61, 223);
  display: flex;
  margin: 0 auto;
  margin-top: 30px;
  border-width: 3px;
  border-style:solid;
  transition: border-color 0.5s linear;
  &:hover {
    border-color: #1DB954;
    transition: border-color 0.5s ease-out ;

  
  }
  
  img {
    margin-top:10%
  }
  
  p {
    font-size: small;
  }

}



.logos {
  position:relative;
  display: flex;
  width: 100%;
  height: 100%;
  flex-wrap: wrap;
  justify-content: space-between;
  column-gap: 7%;
  padding: 4%;
}
.logo {
  /* animation:spin 8s linear infinite; */
  list-style-type: none;
  
}

@keyframes spin { 
  100% { 
      -webkit-transform: rotate(360deg); 
      transform:rotate(360deg); 
  } 
}

.footer {
  position: relative;
  width: 100%;
  display:inline-block; 
  background-color:#252525;
  height: 15vh;
 
  p {
    /* margin-top: 10vh; */
    
  }
 
}



.logo-label {

}


.vertical-timeline-element-subtitle-custom{
  color: "#fff";
  
}

.tags {
  margin-bottom: 10px;
  
}

.tag-list {
  display:flex;
  flex-wrap: wrap;
  gap: 10px;
  white-space: initial;
  margin:0;
  padding:0;
  min-width:100%;
  
}
.tag {
  border: 0px solid;
  border-radius: 50px;
  padding-top:0.03px; 
  padding-bottom:0.05px;  
 
  padding-left: 5px;
  padding-right: 5px;
  min-width:fit-content;
  background-color: #242424;
  &:hover {
    // background-color: #1DB954;
  }
  p {
    min-width: 100px;
  }
}







