

  .playlist-card {
    border-radius: 5px;
    background-color:#444242;
    max-width:100%;
    display: flex;
    flex-direction: row;
    justify-content: left;
    overflow-y: auto;
    transition: all .25s linear;
    
  }
  .playlist-card p {
    display:inline-flexbox;
    color: whitesmoke;
    margin: 6%;
    justify-self: center;
    /* font-size: 70%; */
    font-size: clamp(1.2rem, 2.5vw, 1.5rem);
    
    
  }

  .playlist-card img {
    /* width: 20vw; */
    max-width: 15vw;
    max-height: 30vh;
    min-height: 100%;
    display: relative;
    aspect-ratio: auto;
    /* min-width: px; */
    
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    
   
  }
  .playlist-card body {
    /* margin-left: 3%; */
    font-size: small;
    color: #ccc
  }

  .playlist-card:hover {
    background-color: rgb(97, 95, 95);
    transition: all .25s linear;
    
  }
  .playlists {
    
    display:relative;
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100%;
    background-color: #151515;
    min-height:900px;
    
    
    
    /* flex: 1; */
  }

  .playlists-list {
    margin:50px;
    list-style-type: none;
    justify-content: left;
    position: relative;
    align-items: left;
    width: 100%; 
    font-family: Arial, sans-serif;
    color:#1DB954;
    /* flex: 1; */
  }
  .playlists-list:nth-child(n) {
    margin: 80px;
  }
  .playlists li{
    width: 100%;
    font-size:xx-large;
    margin-bottom: 5%;
    height: 8%;
    
  }
  .playlists a{
    text-decoration: none;
    height:100%;
    color: black;
  } 
  .playlists h2 {
    
  }