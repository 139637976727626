.playlists-info {
   
    list-style-type: none;
    display: flex;
    position: relative;
    height:750px;
    width: 100%;
    background-color: #151515;
    font-family: Arial, sans-serif;
    flex-direction: row-reverse;
    padding:0%;
  }
  .track-list {

    overflow-y: scroll;
    background-color:#151515;
    padding:0;
    margin-top: 2%;
    width: 50%;
    max-height: 80%;
    overflow-x:hidden;
    margin-left: 10%;
    border-top-left-radius: 8px;
  }


  .track-list {
    --mask: linear-gradient(to bottom, 
        rgba(0,0,0, 1) 0,   rgba(0,0,0, 1) 60%, 
        rgba(0,0,0, 0) 95%, rgba(0,0,0, 0) 0
    ) 100% 50% / 100% 100% repeat-x;
    
    font: 2em/1.6em Arial;
    -webkit-mask: var(--mask); 
    mask: var(--mask);
    
  }
  
  .track-list::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  }
  .track-list::-webkit-scrollbar {
    width: 10px; /* Add the unit 'px' after the value */
    height: 2px; /* Specify the height of the scrollbar */
    margin-left: 5px;
}

.track-list::-webkit-scrollbar-thumb {
    background-color: #a8a5a5;
    border-radius: 10px;
    transition-delay: 4s;
    transition: all 2s linear;

}
.track-list::-webkit-scrollbar-thumb:hover {
  background-color: #ccc;
}


.track-list::-webkit-scrollbar-track {
    background-color: transparent;
}
  .track-list a {
    text-decoration: none;
    display: flex;
    max-height:100%;
    width: 100%;
    color: black;
  }
.playlist-averages {
    height: 40%;
    max-width: 40%;
    justify-items: center;
  
    text-align: center;
    
  }
  .playlist-averages ul {
    list-style-type: none;
    /* height: 20%; */
    width: 100%;
    display: flex;
    /* margsin: 5%; */

    justify-content: space-between;
    flex-wrap: wrap;
    row-gap: 2rem;
    column-gap: 3.5rem;
  }
  .playlist-averages li {
    /* display: inline-block; */
    list-style-type: none;
    width: 20%;
    margin-bottom: 5%;
    
  }
  
  
  .track-card img {
    height:10rem;
    display: relative;
    aspect-ratio: auto;
    
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
  }
  .playlist-averages h1 {
    color:#1DB954;
    justify-self: center;
  }
  

  .track-card {
    border-radius: 8px;
    max-height: 10rem;
    background-color:#444242;
    max-width:100%;
    max-height:10rem;
    display: flex;
    flex-direction: row;
    line-height: 80%;

    /* justify-content: left; */
    margin-bottom: 5%;
    border-radius: 5px;
    transition: all .25s linear;

  } 
  .track-card:hover {
    background-color: rgb(97, 95, 95);
    transition: all .25s linear;
  }

  .track-details {
    max-height:100%;
    margin: 3%;
    width:100%;
    /* margin-left:5%; */
    color:whitesmoke;
    font-size: 1.0rem;
    /* display:inline; */
    overflow-y:auto;
  }
  .track-name {
    margin:0;
    padding:0;
  }
  .artist-name {
    margin:0;
    padding:0;
    color:#ccc;
    font-size: 0.8rem;
    
  }
  .track-card p {
    margin:0;
    padding:0;
    
    font-size: 0.8rem;
  }
  .track-card li {
    height: 100%;
  }

  .track-card body {
    color: #ccc;
    font-size:1rem;
    padding:0;
    margin:0;
  }

  .playlist-intro {
    text-align: center;
    background-color: #151515;
  }