.pop-up {
    height: 5%;
    width: 5%; 
    margin: 0px;
    padding-top: 50px;
    background-color: aqua;
    position: absolute;
    transition: all 1s linear;

}

.popup-button {
    display: inline;
    font-size: 1rem;
    font-weight: normal;
    color: whitesmoke;
    text-decoration: none;
    background: none;
    border: 1px solid grey;
    border-radius: 8px;
    cursor: pointer;
    min-width:12rem;
    padding: auto;
    transition: all 0.1s linear;
  }

.popup-button:hover {
  background-color: #1DB954;
}
.popup-card {
  
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 5px;
  margin-bottom: 20px;
  background-color:#333;
  padding-bottom: 2%;
  padding-right: 2%;
  padding-left: 2%;

  width:auto;
  max-width: 50%;
  margin: 10px;
  justify-content:center;
  overflow-y: scroll;
  text-align: center; 
  display: flex;
  
}


.popup-description h1 {

    position:relative;
    font-size: 15px;
    margin-bottom: 0px;
    display: inline;
    color: #1DB954;
    

  }

.popup-description p {
/* padding-top: 5%; */
color:white;
position:relative;
font-size: 15px;
margin-bottom: 0px;



}
